// This file is automatically generated. Do not edit it directly.
// To regenerate this file, run 'bazel run //client/web-sveltekit:write_generated'.
export interface SvelteKitRoute {
    // The SvelteKit route ID
    id: string
    // The regular expression pattern that matches the corresponding path
    pattern: RegExp
    // Whether the route is the repository root
    isRepoRoot: boolean
}

// prettier-ignore
export const svelteKitRoutes: SvelteKitRoute[] = [
    {
        id: '/(app)/[...repo=reporev]/(validrev)/(code)',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/?$'),
        isRepoRoot: true,
    },
    {
        id: '/(app)/[...repo=reporev]/(validrev)/(code)/-/blob/[...path]',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/blob(?:/.*)?/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/(validrev)/(code)/-/tree/[...path]',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/tree(?:/.*)?/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/(validrev)/-/changelists/[...path]',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/changelists(?:/.*)?/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/(validrev)/-/commits/[...path]',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/commits(?:/.*)?/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/-/branches',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/branches/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/-/branches/all',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/branches/all/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/-/changelist/[changelistID]',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/changelist(?:/[^/]+)/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/-/commit/[...revspec]',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/commit(?:/.*)?/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/-/compare/[...rangeSpec]',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/compare(?:/.*)?/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/-/labels',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/labels/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/-/stats/contributors',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/stats/contributors/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[...repo=reporev]/-/tags',
        pattern: new RegExp('^/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/tags/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/[community=communitySearchContext]',
        pattern: new RegExp('^/(backstage|chakraui|cncf|julia|kubernetes|o3de|stackstorm|stanford|temporal)/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/cody/chat',
        pattern: new RegExp('^/cody/chat/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/cody/dashboard',
        pattern: new RegExp('^/cody/dashboard/?$'),
        isRepoRoot: false,
    },
    {
        id: '/(app)/search',
        pattern: new RegExp('^/search/?$'),
        isRepoRoot: false,
    },
    {
        id: '/embed/[...repo=reporev]/-/blob/[...path]',
        pattern: new RegExp('^/embed/(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,})))(?:@(?:(?:(?:[^@/-]|(?:[^/@]{2,}))/)*(?:[^@/-]|(?:[^/@]{2,}))))?/-/blob(?:/.*)?/?$'),
        isRepoRoot: false,
    },
    
]
